import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axiosInstance";
import { toastOnError } from "../../utils/utils";

export const GetInterprterList = createAsyncThunk(
  "getAllInterperters",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("user/interpreters/");

      return response.data;
    } catch (e) {
      toastOnError(e);
      return rejectWithValue(e?.response?.data);
    }
  }
);

export const FollowUnfollowInterperter = createAsyncThunk(
  "FollowUnfollowInterperter",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("user/follow/", data);

      return response.data;
    } catch (e) {
      toastOnError(e);
      return rejectWithValue(e?.response?.data);
    }
  }
);

const InterpretersAdapter = createEntityAdapter({
  selectId: (log) => log.username,
});

const initialState = InterpretersAdapter.getInitialState({
  isFetched: false,
});

const InterpretersReducer = createSlice({
  name: "InterpretersReducer",
  initialState: initialState,
  reducers: {
    switchInterperterOn(state, action) {
      const username = action.payload;
      const interperter = state.entities[username];

      if (interperter && interperter.profile.online === 0) {
        interperter.profile.online = 1;
      }
    },
    switchInterperterOFF(state, action) {
      const username = action.payload;
      const interperter = state.entities[username];
      if (interperter) {
        interperter.profile.online = 0;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetInterprterList.fulfilled, (state, action) => {
      InterpretersAdapter.addMany(state, action.payload);
      state.isFetched = true;
    });
    builder.addCase(FollowUnfollowInterperter.fulfilled, (state, action) => {
      const data = action.payload;
      const interperter = state.entities[data?.username];
      if (interperter) {
        interperter.profile.followers = data?.profile?.followers;
      }
    });
  },
});

export default InterpretersReducer.reducer;

export const {
  selectAll: SelectAllInterpreters,
  selectById: SelectInterpreterByID,
} = InterpretersAdapter.getSelectors((state) => state.interpreters);

export const { switchInterperterOFF, switchInterperterOn } =
  InterpretersReducer.actions;
