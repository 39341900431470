import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { logout } from "../redux/login-slice/login.slice";

function RequireAuth({ children }) {
  const { isAuthenticated, user, AuthSuccessLoading } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();
  if (AuthSuccessLoading) return null;
  if (!isAuthenticated) return <Navigate to="/login" />;
  if (user?.user_type === "INTERPRETER" || user?.user_type === "GUEST") {
    dispatch(logout());
    // return <Navigate to="/login" />;
  }

  return children;
}

export default memo(RequireAuth);
