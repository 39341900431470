import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import NotificationComponent from "../../components/custom-toast/CustomToast";
import axiosInstance from "../../utils/axiosInstance";
const messageAlert = new URL("../../assets/sounds/mony.mp3", import.meta.url);

export const GetActiveTicket = createAsyncThunk(
  "GetActiveTicket",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("user/active-tickets/");
      return response.data;
    } catch (error) {
      toast.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const GetTicketDetail = createAsyncThunk(
  "GetTicketDetail",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`user/ticket_detail/${id}/`);
      return response.data;
    } catch (error) {
      toast.warning("Ticket May Completed Or Not exisit");
      return rejectWithValue(error.message);
    }
  }
);

export const AnswerTicket = createAsyncThunk(
  "AnswerTicket",

  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `user/active-tickets/${data?.ticket}/`,
        data
      );
      return response.data;
    } catch (error) {
      toast.error(error.message);
      return rejectWithValue(error?.data);
    }
  }
);

export const CreateNewTicket = createAsyncThunk(
  "CreateNewTicket",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`user/create_ticket/`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const activeTicketAdpater = createEntityAdapter({
  selectId: (state) => state.id,
});

const initialState = activeTicketAdpater.getInitialState({
  loading: false,
});

const activeTicketSlice = createSlice({
  name: "activeTicket",
  initialState: initialState,
  reducers: {
    setSingleActiveTicket(state, action) {
      activeTicketAdpater.addOne(state, action.payload);
      toast.info(
        <NotificationComponent
          url={`/dashboard/ticket/${action.payload.id}`}
          message={`${action.payload.user.username} sent a ticket`}
        />
      );
      const messageAlarm = new Audio(messageAlert);
      messageAlarm.play();
    },
    updateSingleActiveTicket(state, action) {
      const ticket_id = action.payload.ticket;
      const ticket = state.entities[ticket_id];
      if (action.payload?.data) {
        ticket.ticket_reply.push(action.payload.data);
      }
      if (action.payload?.status) {
        ticket.status = action.payload.status;
      }
      // activeTicketAdpater.upsertOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetActiveTicket.fulfilled, (state, action) => {
        activeTicketAdpater.upsertMany(state, action.payload);
        state.loading = false;
      })
      .addCase(GetActiveTicket.rejected, (state, action) => {
        state.loading = false;
      })
      // .addCase(AnswerTicket.fulfilled, (state, action) => {
      //   if (action.payload.status !== "PENDING") {
      //     // activeTicketAdpater.removeOne(state, action.payload.id);
      //   }
      // })
      .addCase(GetTicketDetail.fulfilled, (state, action) => {
        activeTicketAdpater.upsertOne(state, action.payload);
      });
    // .addCase(CreateNewTicket.fulfilled, (state, action) => {
    //   activeTicketAdpater.upsertOne(state, action.payload);
    // });
  },
});
export const {
  selectAll: selectAllActiveTicket,
  selectById: selectActiveTicketById,
  selectIds: selectActiveTicketIds,
} = activeTicketAdpater.getSelectors((state) => state.activeTicket);

export const { setSingleActiveTicket, updateSingleActiveTicket } =
  activeTicketSlice.actions;

export default activeTicketSlice.reducer;
