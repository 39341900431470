import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../utils/utils";

// import { getAPIUrl } from '../utils/Utils';

const baseQuery = fetchBaseQuery({
  baseUrl: baseURL,

  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests

    const { token } = getState().auth;

    if (token) {
      headers.set("Authorization", `Token ${token}`);
    }
    return headers;
  },
});

// const baseQueryWithRetry = retry(baseQuery, { maxRetries: 6 })

const api = createApi({
  reducerPath: "api",
  baseQuery,
  tagTypes: [
    "ITUsers",
    "MissingKpi",
    "KPILIST",
    "Tickets",
    "Leaves",
    "HistoricalData",
    "UserLeaveRequests",
    "CompletedLeaves",
  ],
  endpoints: () => ({}),
});

export default api;
