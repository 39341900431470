import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axiosInstance";
import { toastOnError } from "../../utils/utils";

// import { toast } from "react-toastify";
// import CustomToast from "../../components/custom-toast/CustomToast";

// const messageAlert = new URL("../../assets/sounds/soft.mp3", import.meta.url);

export const GetITLogs = createAsyncThunk(
  "getItAllLogs",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("script/it-list/");

      return response.data;
    } catch (e) {
      toastOnError(e);
      return rejectWithValue(e?.response?.data);
    }
  }
);

const ITLogsAdapter = createEntityAdapter({
  selectId: (log) => log.username,
});

const initialState = ITLogsAdapter.getInitialState({
  isLoading: false,
  isFetched: false,
});

const ITReducer = createSlice({
  name: "ITReducer",
  initialState: initialState,
  reducers: {
    appendNetworkLog(state, action) {
      const data = action.payload;
      const log_for = state.entities[data.message.data.username];
      if (log_for) {
        log_for.network_monitors.push(data.message.data);
      }
    },
    appendDeviceLog(state, action) {
      const data = action.payload;
      const userLogs = state.entities[data.message.data.username];
      if (userLogs) {
        userLogs.device_monitors.push(data.message.data);
      }
    },
    appendAppUsageLog(state, action) {
      const data = action.payload.message.data;
      const log_for = state.entities[data.username];
      console.log(data);
      if (log_for) {
        // log_for.app_usage.push(data.message.data);
        const currentLog = log_for.app_usage[log_for.app_usage.length - 1];
        if (currentLog?.active_app_title !== data?.active_app_title) {
          currentLog.previous_active_app_title =
            currentLog?.active_app_title ??
            currentLog.previous_active_app_title;
          currentLog.previous_active_app_process =
            currentLog?.active_app_process ??
            currentLog.previous_active_app_process;
          currentLog.active_app_title =
            data?.active_app_title ?? currentLog.active_app_title;
          currentLog.active_app_process =
            data?.active_app_process ?? currentLog.active_app_process;
          currentLog.switch_time = new Date().toString();
          currentLog.active_app_memory =
            data?.active_app_memory ?? currentLog.active_app_memory;
          currentLog.url = data?.url ?? currentLog.url;
        }

        if (data?.opened_apps?.length > 0) {
          currentLog.opened_apps = data?.opened_apps;
        }
      }
    },
    // userDisconnect(state, action) {
    //   const data = action.payload;
    //   const user = state.entities[data.message.data];
    //   if (user) {
    //     user.profile.online = 0;
    //   }
    // },
    appendUserLogs(state, action) {
      const data = action.payload;
      const log_for = state.entities[data.message.data?.[0]?.username];
      if (log_for) {
        log_for.daily_logs = data.message.data;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetITLogs.fulfilled, (state, action) => {
      ITLogsAdapter.addMany(state, action.payload);
      state.isFetched = true;
    });
  },
});

export default ITReducer.reducer;

export const { selectAll: SelectAllITLogs, selectById: SelectLogByID } =
  ITLogsAdapter.getSelectors((state) => state.it);

export const {
  appendAppUsageLog,
  appendDeviceLog,
  appendNetworkLog,
  appendUserLogs,
  // userDisconnect,

  // changeHeadset,
} = ITReducer.actions;
