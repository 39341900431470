import axios from "axios";
import { toast } from "react-toastify";

export const setAxiosAuthToken = (token) => {
  if (typeof token !== "undefined" && token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

export const toastOnError = (error) => {
  if (error.response.data) {
    if (error.response.data.extra?.fields?.non_field_errors) {
      toast.error(JSON.stringify("email or password invalid"));
    } else if (error.response.data?.extra?.fields?.password) {
      toast.error(JSON.stringify("password invalid"));
    } else if (error.response?.extra?.fields?.data?.email) {
      toast.error(JSON.stringify("invalid email"));
    } else if (error.response.data?.extra?.fields?.username) {
      toast.error(JSON.stringify("invalid username"));
    } else {
      toast.error(JSON.stringify(error.response.data?.message));
    }
    // known error
    // toast.error(JSON.stringify(error.response.data));
  } else if (error.message) {
    toast.error(JSON.stringify(error.message));
  } else {
    toast.error(JSON.stringify(error));
  }
};

export const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  (typeof value === "object" && Object.keys(value).length === 0) ||
  (typeof value === "string" && value.trim().length === 0);

let baseURL;
let socketUrl;
const protocol = window.location.protocol;
const host = window.location.host;

if (window.location.origin === "http://localhost:3000") {
  baseURL = "http://127.0.0.1:8000/api/";
  socketUrl = `ws://127.0.0.1:8000/`;
} else {
  baseURL = `${window.location.origin}/api/`;
  socketUrl = `${protocol === "http:" ? "ws:" : "wss:"}${host}/`;
}

export { baseURL, socketUrl };

export function byteToMegaBit(num) {
  return (num * 8 * 10 ** -6).toFixed(2);
}

export function format_duration(duration) {
  if (duration) {
    let formated = duration.split(":");
    if (formated.length === 3) {
      formated[2] = parseFloat(formated[2]).toFixed(1);
      return formated.join(":");
    }
  }
  return duration;
}

export function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export function formatBatteryTimeRemaining(timeInMinutes) {
  if (timeInMinutes <= 0) return "مخبرش";

  const hours = Math.floor(timeInMinutes / 60);
  const minutes = Math.floor(timeInMinutes % 60);

  if (hours > 0) {
    return `${hours}h ${minutes}m`;
  }
  return `${minutes}m`;
}

export function openSidebar(sideName) {
  if (typeof window !== "undefined") {
    document.body.style.overflow = "hidden";
    document.documentElement.style.setProperty(`--${sideName}-slideIn`, "1");
  }
}

export function closeSidebar(sideName) {
  if (typeof window !== "undefined") {
    document.documentElement.style.removeProperty(`--${sideName}-slideIn`);
    document.body.style.removeProperty("overflow");
  }
}

export function toggleSidebar(sideName) {
  if (typeof window !== "undefined" && typeof document !== "undefined") {
    const slideIn = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue(`--${sideName}-slideIn`);
    if (slideIn) {
      closeSidebar(sideName);
    } else {
      openSidebar(sideName);
    }
  }
}

export function normalizeLeaveDay(days) {
  // Round to nearest quarter day (0.25, 0.5, 0.75, 1.0)
  return Math.round(days * 4) / 4;
}

export const getDifferentMonth = (lastDate, firstDate = new Date()) => {
  const date1 = firstDate;
  const date2 = new Date(lastDate);
  const diffTime = Math.abs(date2.getTime() - date1.getTime());
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  const diffMonth = Math.floor(diffDays / 30);
  return diffMonth;
};
